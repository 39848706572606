import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"
import Bitty_logo from "../../images/bitty-biddy/bitty-logo.png"
import Bitty_cart from "../../images/bitty-biddy/bitty_top.jpg"
import Bitty_shop from "../../images/bitty-biddy/google-shopping.jpg"
import Approch from "../../images/bitty-biddy/approach-right.png"
import Challnage from "../../images/bitty-biddy/challange-left.jpg"
import Bitty_li from "../../images/bitty-biddy/li-icon.png"
import Bitty_right from "../../images/bitty-biddy/biddy-right.png"
import Bitty_left from "../../images/bitty-biddy/biddy-left.png"


import Perennialcycle_process from "../../images/perennialcycle/graphics.png"
import Pernail_cycle2 from "../../images/perennialcycle/cycle2.jpg"

import Calltoaction from "../../components/Call-to-action"

import Scroll_top_img from "../../images/scroll-arrow.png"
import {Helmet} from "react-helmet"

import { Link } from "gatsby";

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                 <title>Case Study for Google Shopping Engines - Betty And Biddy</title>
                    <meta name="description"
                        content="Case study on how CueForGood’ Google Shopping Campaigns have been delivering consistent results for Betty and Biddy. Increased Revenue, more traffic, higher engagement:
It’s been all that and more since then." />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="Case Study for Google Shopping Engines - Betty And Biddy" />
                    <meta property="og:description"
                        content="Case study on how CueForGood’ Google Shopping Campaigns have been delivering consistent results for Betty and Biddy. Increased Revenue, more traffic, higher engagement:
It’s been all that and more since then." />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="Case study on how CueForGood’ Google Shopping Campaigns have been delivering consistent results for Betty and Biddy. Increased Revenue, more traffic, higher engagement:
It’s been all that and more since then." />
                    <meta name="twitter:title"
                        content="Case Study for Google Shopping Engines - Betty And Biddy" />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner">
                  <div className="case_study_page perennialcycle bitty-casestudy">
                     <div className="case-wrap  ">
                           <div className="ww-sec-one ">
                          <img className="fixed-right" src={Bitty_right} alt="Bitty and Biddy " />
                          <img className="fixed-left" src={Bitty_left} alt="Bitty and Biddy " />
                              <div className="container">
                                 <h3>Case Study</h3>
                                 <h1><img className="bitty_logo" src={Bitty_logo} alt="Bitty and Biddy logo" /></h1>
                                 <p className="perennialcycle_p">How CueForGood’ Google Shopping Campaigns have been delivering consistent results for Betty and Biddy. </p>
                                 <div className="cycle-top">
                                    <img src={Bitty_cart} alt="Bitty and Biddy Cart" />
                                    <img src={Bitty_shop} alt="Bitty and Biddy shop" />
                                    </div>

                                 <div className="revenue-perennial">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                          <div className="revenue-single-outer">
                                             <div className="progress blue1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 1607%</div>
                                             </div>
                                          </div>
                                          <p>Increase in traffic</p>
                                       </div>

                                       <div className="revenue-single circle3">
                                          <div className="revenue-single-outer">
                                             <div className="progress pink1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 1772%</div>
                                             </div>
                                          </div>
                                          <p>Increase in conversions</p>
                                       </div>
                                       <div className="revenue-single circle4">
                                          <div className="revenue-single-outer">
                                             <div className="progress green1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 1699%</div>
                                             </div>
                                          </div>
                                          <p>Increase in revenue</p>
                                       </div>
                                    </div>
                                 </div>


                                 <div className="bitty-increase-revenue">
                         
                                <p className="note"><strong>NOTE:</strong> Comparing the results of Google Shopping Engine from November 2017 to September 2020</p>
                                    <div className="other-info">

                                    <div className="cb_good_section call-action-highroas beddybitty">
                                       <Calltoaction sid="ansel1"/>
                                    </div>  

                                   <p> Betty and Biddy is an online store that carries a wide range of fast fashion jewelry - from necklaces, bracelets, earrings, rings to pendants, 
                                      and more. They knew that to succeed in this uber-competitive market of fashion jewelry you have to be as hard as a jewel and twice as bright.  
                                    </p>
                                    <p>
                                   
                                      They partnered with CueForGood in 2017 to start Google Shopping Campaigns.  Increased Revenue, more traffic, higher engagement:<br/>
                                       It’s been all that and more since then.  </p>
                                    </div>

                                 </div>
                              </div>
                           </div>
                     </div>

                     
                 
                    <div className="biddy-approach">
                       <div className="container">
                        <div className="approach-upp">
                             <div className="approach-upp-right">
                                <img src={Approch} alt="Bitty-biddy Approach" />
                             </div>
                             <div className="approach-upp-left">
                                <h2 className="heading_main">Our Approach</h2>
                                <p>Unlike several other advertising channels, Google Shopping Engine ads are supported by product pictures, prices, and reviews (if any). 
                                   To add to this, they allow the user to compare the product pricing and reviews with others instantly. Hence, these ads become a great source of relevant traffic 
                                   for stores with quality products. </p>
                                <p>Not only this, as the traffic is usually highly relevant, there are more chances of conversion. Data Feed Optimization (DFO) is very important to dominate
                              the competition on the Google Shopping Engine. </p>
                                <p>The ever-growing demand for fast-jewelry online opened the doors of opportunity for Betty and Biddy. Nowadays, people prefer shopping on their
                                mobile phones from the comfort of their homes than actually visiting the stores. Therefore, It was a point of paramount significance that every
                                   potential customer should be able to locate Betty and Biddy’s products on the top of the Search Engine with ease. </p>
                                <p>
                                   The Google Shopping Engine is how a majority of users search the web. Supplying optimized product data feeds to Google Shopping Engine can help online retailers grow by generating sterling traffic and revenue.
                                 </p>
                                <p>
                                   By including all the requirements of the Google Shopping Engine and updating the feed whenever there is a price change, a product goes out of stock,
                               or a new product is added to the site.  </p>
                             </div>
                             
                        </div>

                          <div className="approach-upp challnage">
                           <div className="approach-upp-right">
                              <img src={Challnage} alt="Bitty-biddy challanges" />
                           </div>
                             <div className="approach-upp-left">
                                <h2 className="heading_main">Challenges </h2>
                                <p className="half-p">This project brought a lot of Google Shopping Engine challenges in our way that we combated with the team’s expertise and extensive knowledge.
                                <br/>
                              The goal was to manage the data feed of products for better performance and to achieve this we had to:  </p>
                                <ul>
                                   <li><img src={Bitty_li} alt="Bitty-biddy listing" /> Promote individual products while comparing prices with the competitors. </li>
                                   <li><img src={Bitty_li} alt="Bitty-biddy listing" /> Promote individual products by ranking them on product search.</li>
                                   <li><img src={Bitty_li} alt="Bitty-biddy listing" /> Reach a larger audience through Google Shopping Engine.</li>
                                </ul>
                                <p>To increase the traffic on Betty and Biddy’s website, our priority was to feed product information to the Shopping Engine for a comprehensive approach.
                                To ensure that each product is in the right place for the right consumer we reviewed product catalogs, configured fields, created custom attributes,
                                specific titles, meta descriptions, and ensured proper categorization.</p>
                             </div>
                             
                          </div>
                         
                       </div>
                    </div>

                    <div className="cb_good_section call-action-component">
                        <div className="service_weoffer cb_testimonails">
                        <Calltoaction sid="biddy2"/>
                        </div>
                     </div>  

                    <div className="biddy-result">
                       <div className="container">
                          <div className="biddy-challanges-bottom-right">
                             <h2 className="heading_main">Results </h2>
                             <p className="half-p">Fast forward three years and the collaboration is still going strong. Over the past three years, the overall traffic has increased by <strong> 1607.77%</strong>, conversions by <strong>1772.00%</strong>, and revenue by <strong>1699.02%.</strong> Betty and Biddy are still taking a shine to the results we have achieved.  </p>
                             <p>Write to us at <a href="mailto:letstalk@cueforgood.com">letstalk@cueforgood.com</a> to understand how we can add value to your business. </p>
                          </div>
                       </div>
                    </div>
        

                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>

                <Clutch />

            </Layout>
        )
    }
}